/*
This is where you write custom SASS
*/

.btn-primary {
    background-color: #7F5E71 !important;
}
.btn-primary:hover {
    background-color: #957A89 !important;
}
header {
    border-top: 3px solid #7F5E71;

    .ph {
        a {
            font-size: 16px;
            color: #7F5E71 !important;
            font-weight: bold;
            letter-spacing: 0.96px;
        }
        a:hover {
            color: rgb(94, 71, 85) !important;
        }
    }
    .mod {
        font-size: 14px;
        font-weight: normal;
        color: #3E2F38;
        letter-spacing: 0.84px;
    }
}
.navbar {
    border-top: 1px solid #E9ECEF;

    .nav-social {
        position: absolute;
        right: 0;
        top: 5px;

        
    }
    .navbar-nav {
        width: 95%;
    }
}
.navbar ul.navbar-nav > li > a > .sub-arrow::after {
    content: "\f078";
    font-family: "Font Awesome 5 Pro";
    display: inline-block;
    padding-right: 6px;
    vertical-align: middle;
    font-size: 11px;
    margin-left: 5px;
    font-weight: lighter;
    position: relative;
    bottom: 1px;
  }
  .navbar ul.navbar-nav > li .dropdown-menu li a .sub-arrow::after {
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    display: inline-block;
    padding-right: 6px;
    vertical-align: middle;
    font-size: 11px;
    font-weight: lighter;
  }
  .main-slide {
      .mod {
          height: 867px;
      }
  }
.appt {
    background-color: rgba(167, 207, 194, .85);
    min-height: 265px;
    padding: 50px 0 0 0;
    position: absolute;
    width: 100%;
    margin: -265px 0 0 0;
    z-index: 1;

    p {
        font-size: 36px;
        font-weight: 400;
        margin-bottom: 30px;
    } 
    .fa-calendar-check {
        font-size: 16px;
    }
}
.addrs {
    margin: -30px 0 0 0;
    z-index: 1;
    position: relative;

    .addr1 {
        background-color: #fff;
        border-radius: 3px;
        border-top: 3px solid #7F5E71;
        box-shadow: 0 1.5rem 2rem rgba(0,0,0,.10);
        min-height: 220px;
        padding: 30px 50px;
    }
    .addr2 {
        background-color: #fff;
        border-radius: 3px;
        border-top: 3px solid #7F5E71;
        box-shadow: 0 1.5rem 2rem rgba(0,0,0,.10);
        min-height: 220px;
        padding: 30px 50px;
    }
    .location {
        font-size: 16px;
        font-weight: bold;
        color: #5A6D7E;
        letter-spacing: 0.96px;
    }
    h5 {
        font-size: 24px;
        color: #212529;
        line-height: 34px;
        font-weight: normal;
    }
    P {
        a {
            font-size: 13px;
            font-weight: bold;
            letter-spacing: 0.52px;

            .fa-long-arrow-right {
                font-size: 16px;
                position: relative;
                top: 1.7px;
            }
        }
    }
}
.ctas {
    .cta1, .cta2, .cta3 {
        min-height: 350px;
        box-shadow: 0 1.5rem 2rem rgba(0,0,0,.10);

        .mod {
            background-color: rgba(127,94,113,.8);
            min-height: 125px;
            padding: 35px 40px;

            a {
                font-size: 13px;
                font-weight: bold;
                letter-spacing: 0.52px;

                .fa-long-arrow-right {
                    font-size: 16px;
                    position: relative;
                    top: 1.7px;
                }
            }
        }
        .h4 {
            font-size: 22px;
            font-weight: normal;
        }
    }
}
.services {
    background: #ffffff url('/prositesStyles/StyleLibrary/6320/images/testimonials-bg.png');
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: cover;

    .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev {
        display: none !important;
    }
    .owl-theme .owl-nav.disabled + .owl-dots {
        margin-top: 10px;
        position: relative;
    }
    blockquote {
        p {
            font-weight: normal !important;
            line-height: 42px !important;
            font-size: 32px !important;
        }
        a {
            color: #fff;
        }
    }
    cite {
        font-size: 14px;
        font-style: normal;
    }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
        background: #fff;
    }
    .owl-theme .owl-dots .owl-dot span {
        width: 8px;
        height: 8px;
        margin: 5px 7px;
        background: rgba(255,255,255,.50);
    }
    .serv-wrap {
        background-color: #fff;
        padding: 50px;
        min-height: 535px;
        box-shadow: 0 1.5rem 2rem rgba(0,0,0,.10);

        .title {
            color: #212529;
        }

        .text-1 {
            color: #7F5E71;

            h6 {
                position: relative !important;
                z-index: 1;
                font-size: 14px;
                font-weight: bold;

                span {
                    background-color: white;
                    padding-right: 10px;
                }
            }
            h6::after {
                content:"";
                position: absolute;
                bottom: 2px;
                left: 30px;
                right: 0;
                height: 0.5em;
                border-top: 2px solid #E9ECEF;
                z-index: -1;
            }
            
        }
        .serv-text {
            p {
               padding-left: 24px; 
               font-size: 14px;
               font-weight: normal;
               color: #495057;
            }
        }
        
        a {
            font-size: 13px;
            color: #5A6D7E;
            letter-spacing: 0.52px;
        }
    }
}
section.main-contact [id*="CaptchaPanel"]::after {
    content: "\f0e0";
    font-family: 'Font Awesome 5 Pro';
    color: #fff;
    position: absolute;
    left: 49px;
    top: 502px;
    width: 1em;
    font-weight: lighter;
}
section.main-contact input.btn {
    border: transparent;
    position: relative;
    box-shadow: none;
    color: #fff;
    padding: 20px 0 20px 26px;
    width: 128px;
    margin-top: 20px;
}
.main-contact {
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(0,0,0,.38);
        opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: rgba(0,0,0,.38);
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: rgba(0,0,0,.38);
    }
    .form-control {
        padding: 1.5rem .75rem;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 2px solid rgba(0,0,0,.15);
        border-radius: 4px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .form-group {
        margin-bottom: 8px;
        margin-top: 8px !important;
    }
    textarea {
        padding: 1rem .75rem !important;
        min-height: 117px;
    }
    .tag {
        color: #495057;
        font-size: 16px;
        font-weight: normal;
    }
    h2 {
        font-size: 32px;
        font-weight: bold;
        color: #495057;
        letter-spacing: 1.92px;
    }
}
.bg-foot {
    background-color: #7F5E71;
    margin: -8px 0 0 0;

    br {
        display: none;
    }
    .foot-assoc {
        margin: 0 auto;
        display: block;
    }
    .addr {
        font-size: 20px;
        font-weight: normal;
        letter-spacing: 1.2px;
    }
    .foot-btm {
        color: rgba(255,255,255,.5);
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0.72px;

        a {
            color: rgba(255,255,255,.5);
        }
        a:hover {
            color: rgba(255,255,255,1);
        }
    }
    .foot-btm2 {
        color: rgba(255,255,255,.5);
        font-size: 12px;
        font-weight: normal;

        a {
            color: rgba(255,255,255,.5);
        }
        a:hover {
            color: rgba(255,255,255,1);
        }
    }
}
.hvr-glow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
}
.hvr-glow:hover, .hvr-glow:focus, .hvr-glow:active {
    box-shadow: 0 5px 10px rgba(0, 0, 0, .4);
}
.hvr-opac:hover, .hvr-opac:focus, .hvr-opac:active {
    opacity: 0.9;
    -webkit-transition: all .35s;
    -moz-transition: all .35s;
    transition: all .35s;
}
.color1 {
    color: #7F5E71;
}
.color1:hover {
    color: #5e4755;
}
.bg-grn {
    background-color: #CCE5DC;
}
.interior section.main-contact input#btnSend {
    background-color: #7F5E71;
}
.interior section.main-contact input#btnSend:hover {
    background-color: #6d5161;
}
.interior .main-contact .form-control {
    border: 2px solid #d9d9d9;
}
.interior h1 {
    margin-bottom: 30px;
}
.interior {
    .form-group label {
        display: none;
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(0,0,0,.38);
        opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: rgba(0,0,0,.38);
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: rgba(0,0,0,.38);
    }
    .form-control {
        border: 2px solid #d9d9d9;
        padding: 1.5rem .75rem;
    }
    .card-body {
        background-color: #CCE5DC;
        padding: 45px 40px;
        border-radius: 4px;
    }
    textarea {
        padding: 1rem .75rem !important;
        min-height: 117px;
        margin-bottom: 20px;
    }
    .form-group {
        margin-bottom: 8px;
        margin-top: 8px !important;
    }
    .mb-3 {
        font-size: 13.5px;
        font-weight: normal;
        color: #495057;
        margin-bottom: 30px;
        letter-spacing: -0.195px;
    }
    .h3 {
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 1.68px;
    }
}




@media (max-width: 1200px) {
    .appt p {
        font-size: 30px;
    }
    .appt h3 {
        font-size: 30px;
    }
    section.main-contact [id*="CaptchaPanel"]::after {
        top: 526px;
    }
    .ctas .cta3 .h4 {
        font-size: 18px;
    }
    .main-contact .mr-95px {
        margin-right: 80px !important;
    }
    .services .mr-95px {
        margin-right: 80px !important;
    }
    .addrs h5 {
        font-size: 21px;
    }
}
@media (max-width: 992px) {
    .appt p {
        font-size: 23px;
        margin-bottom: 15px;
    }
    .bg-foot .addr {
        font-size: 14px;
        line-height: 17px;
    }
    .maps .map1 {
        margin: 0 0 -8px 0;
    }
    .main-contact .form {
        margin-bottom: 50px;
    }
    section.main-contact [id*="CaptchaPanel"]::after {
        top: 478px;
        left: 330px;
    }

    // section.main-contact [id*="CaptchaPanel"]::after {
    //     top: 495px;
    //     left: 330px;
    // }
    .main-contact {
        text-align: center;
    }
    .services .serv-wrap {
        margin-bottom: 50px;
    }
    .services .test .owl-dots {
        text-align: center !important;
    }
    .services .test {
        text-align: center;
        margin-bottom: 30px;
    }
    .services .mr-95px {
        margin-right: 0 !important;
    }
    .main-contact .mr-95px {
        margin-right: 0 !important;
    }
    .main-content .main-pic {
        margin-top: 30px;
    }
    .addr1 {
        margin-bottom: 30px;
    }
    .appt h3 {
        font-size: 22px;
        margin-bottom: 20px;
    }
    .appt {
        min-height: 185px;
        margin: -185px 0 0;
        padding: 30px 0;
    }
    .main-slide {
        .mod {
            height: 485px;
        }
    }
    .navbar {
        padding: 10px 0 !important;
    }
}
@media (max-width: 768px) {
    .bg-foot .foot-assoc img {
        margin: 15px !important;
    }
    section.main-contact [id*="CaptchaPanel"]::after {
        display: none;
    }
    section.main-contact input.btn {
        padding: 20px 20px 20px 26px;
        width: 100%;
    }
    .main-slide .mod {
        height: 330px;
    }
    .bg-foot .addr {
        font-size: 14px;
        line-height: 24px;
    }
    section.main-contact [id*="CaptchaPanel"]::after {
        top: 495px;
        left: 240px;
    }
    .services blockquote p {
        line-height: 30px !important;
        font-size: 22px !important;
    }
    .appt {
        display: none;
    }
}
@media (max-width: 576px) {
}
@media (max-width: 480px) {
    .addrs h5 {
        font-size: 18px;
        line-height: 28px;
    }
    .services .serv-wrap {
        padding: 35px 20px;
    }
}
@media (max-width: 320px) {}